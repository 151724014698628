.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.me {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (min-width: 768px) {
    padding: 0 24px;
  }
}

.descriptions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

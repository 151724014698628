.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}

.rightPart {
  display: flex;
  gap: 4px;
}

.description {
  display: flex;
  flex-direction: column;
}

.lang {
  display: flex;
  background-color: white;
}

.select {
  min-width: 60px;
}

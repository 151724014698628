.root {
  text-decoration: none;
}

.card:hover {
  background-color: #fdfdfd;
}

.titleContainer {
  display: flex;
  cursor: inherit;
}

.title {
  cursor: pointer;
}

.title:hover {
  text-decoration: underline;
}

.mainContent {
  display: flex;
  gap: 16px;
}

.description {
  flex: 1;
}

.media {
  width: 50px;
  height: 50px;
}

.footer {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dateAndLinks {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

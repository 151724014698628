.root {
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

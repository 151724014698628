.root {
  border-radius: 50%;
  overflow: hidden;
  width: calc(2140px / 10);
  height: calc(1672px / 10);
}

.image {
  width: 100%;
  height: 100%;
}

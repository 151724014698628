.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.email {
  display: flex;
  align-items: center;
  gap: 8px;
}

.linkedin {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.linkedinQrCodeContainer {
  justify-self: center;
  align-self: center;
  overflow: hidden;
  width: 200px;
  height: 200px;
}

.linkedinQrCodeImage {
  width: 100%;
  height: 100%;
}

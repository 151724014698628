.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  max-width: 1000px;
  margin: auto;

  & > header {
    display: flex;
    padding: 8px 16px;
    margin-bottom: 8px;
  }

  & main {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 16px;
  }

  & footer {
    display: flex;
    padding: 8px 16px;
    margin-top: 8px;
  }
}

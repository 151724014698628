.root {
  text-decoration: unset;
}

.root:hover {
  text-decoration: underline;
}

.root:active {
  color: darkblue;
}
